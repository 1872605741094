import { combineReducers } from "redux";
import { loadingBarReducer as loadingBar } from "react-redux-loading-bar";
import { pagesSlice } from "./slice";

const rootReducer = combineReducers({
  loadingBar,
  pages: pagesSlice.reducer,
});

export default rootReducer;
